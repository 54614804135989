import React from "react";
import { useInView } from "react-intersection-observer";
import { useStaticQuery, graphql } from "gatsby";
import { Section } from "components/pages/course-page/offer/style";
import { CourseStatusTag } from "components/index/courses-order-archive/style";
import { getCourseStatusIcon } from "utilities/course-utils";
import COURSE_STATUSES from "constants/course-statuses";
import BuyButtonComponent from "../buttons/BuyButtonComponent";
import * as SC from "./style";

const GranichFigmaOffer = ({ data = {} }) => {
  const image = useStaticQuery(graphql`
    query granichFigmaAuthorImage {
      placeholderImage: file(
        relativePath: { eq: "granich-figma/vadim-popup.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 100, quality: 90, layout: CONSTRAINED)
        }
      }
    }
  `);

  const imageData = image.placeholderImage.childImageSharp.gatsbyImageData;

  const { ref, inView } = useInView({ threshold: 0 });

  return (
    <Section>
      <SC.StyledContainer>
        <SC.StyledWrapper type={data.coursetype}>
          <SC.CourseLogoWrapper>
            <SC.StyledGranichLogoHands />
            <SC.StyledGranichFigmaLogo />
          </SC.CourseLogoWrapper>
          <SC.Info>
            <SC.StyledTitle>Granich Figma</SC.StyledTitle>
            <SC.Tags>
              <SC.StyledTag big>Мастер-класс (МК)</SC.StyledTag>
              <CourseStatusTag big courseStatus={COURSE_STATUSES.open}>
                {getCourseStatusIcon(true)}В продаже
              </CourseStatusTag>
            </SC.Tags>
            <SC.StyledDescr type={data.coursetype}>
              <span>
                Тот самый мастер-класс по Фигме, который хочется пройти
                до конца.
              </span>
              <span>
                Всё как вы любите — с видео-уроки от Гранича. Идёт легко как
                питерская шаверма. Уютно, словно вечером на кухне болтаем
                за дизайн.
              </span>
            </SC.StyledDescr>
            <BuyButtonComponent ref={ref} to="#participation-section" />
          </SC.Info>
          <SC.GranichDialogPopup>
            <SC.VadimPopupImageWrapper>
              <SC.VadimPopupImage
                image={imageData}
                style={{ backgroundSize: "cover" }}
                alt="vadim-granich"
              />
            </SC.VadimPopupImageWrapper>
            <SC.PopupInfo>
              <SC.PopupName>Vadim Granich</SC.PopupName>
              <SC.PopupText>
                Куку, мои хорошие! Сделаем жюжь в Фигме?
              </SC.PopupText>
            </SC.PopupInfo>
            <SC.PopupTiming>12h</SC.PopupTiming>
          </SC.GranichDialogPopup>
          <SC.StyledGranichFigmaCursor />
          <SC.StyledLilGranichFigmaCursor />
          <SC.StyledArsenyFigmaCursor />
          <SC.StyledFigCloudIcon />
          <SC.StyledStarIcon />
          <SC.StyledKittyIcon />
        </SC.StyledWrapper>
      </SC.StyledContainer>
      {!inView && (
        <SC.StyledFixedBuyButtonComponent to="#participation-section" />
      )}
    </Section>
  );
};

export default GranichFigmaOffer;
